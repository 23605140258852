.navbar{
     background-image: linear-gradient(135deg,#4d4a4a, #67696b);
     position: fixed;
     width: 100%;
     z-index: 2;
}
li{
     list-style-type: none;
}
.navLink{
     float: left;
     padding: 10px;
     text-decoration: none;
     color: inherit;
     font-weight: 1000;
}
li a:hover {
     background-color: #9c0ba4;
}