.column1{
     float: left;
     width: 20%;
     padding: 2%;
     text-align: center;
}
.column2{
     float: right;
     width: 72%;
     padding: 2%;
}
.gif, .htmlInfo {
     float:left;
     width: 46%;
     padding: 2%;
}
.projects{
     width: 450px;
     height: 550px;
     margin: 5px auto;
     padding: 10px;
     border: outset #333333;;
     background-color: #2b2b2b;;
} 
.project-info{
     text-align: left;
     padding: 20px;
}
.projectbar {
     list-style-type: none;
     margin: 0;
     padding: 0;
     /* overflow: hidden; */
}
.projlink{
     float: left;
     color: #fff;
     text-decoration: underline;
}
a {
     display: block;
     padding: 10px;
     text-decoration: none;
     color: inherit;
}
li a:hover {
     background-color: #9c0ba4;
}
.projactive {
     color: #0ba49c;
     text-decoration: underline;
}
#projectsSmall{
     display: none;
}

@media screen and (max-width: 900px) {
     .column1, .column2{
          float: none;
          width: 100%;
     }
     .column1 {
          display: inline-block;
          width: 95%;
          margin: 0;
          padding: 0;
     }
     .projects {
          width: 400px;
          margin: 0;
          padding: 0;

     }
     .gif, .projectInfo {
          float: none;
          width: 90%;
          padding: 0;
          margin: 0;
     }
}
@media screen and (max-width: 450px) {
     #javascript, .column1, .column2, #html, .gif, .htmlInfo {
          display: none;
     }
     #projectsSmall {
          display:inherit;
          text-align: left;
          margin: 30px;
     }
}