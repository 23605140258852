
#resume{
     margin: 20px;
     text-align: center;
     clear:both;
}
#docIFrame {
     width: 800px;
     height: 1066px;
     margin: auto;
     border: solid 1px lightgray;
  }
  #download{
       text-align: left;
  }
  @media screen and (max-width: 900px) {
       #docIFrame {
            display: none;
       }
  }