#selfpic{
     border-radius: 50%;
}
.self{
float: left;
width: 20%
}
.aboutme{
float: right;
width: 70%;
}
.content{
     text-align: left;
}
.language{
     clear: both;
     display: inline-block;
     padding: .2em .6em .3em;
     margin: 2px;
     font-size: 75%;
     font-weight: 700;
     line-height: 1;    
     background-color:#0b5fa4;
     text-align: left;
     line-height: 1;
     border-radius: .25em;
     white-space: nowrap;
}

@media screen and (max-width: 900px) {
     .self, .aboutme {
          float: none;
          width: 100%;
     }
}