*{
  padding: 0;
  margin: 0;
}
body {
  background-color: #2b2b2b;
  color: #fff;
  text-align: left;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3{
  clear: both;
  text-align: center;
  margin: 20px;
}
.links {
  color:  #0ba49c;
  display: inline;
}
.content{
  clear: both;
  margin: 30px;
}
 
